<template>
  <v-container class="px-6">
    <div class="d-flex">
      <div class="d-flex align-center py-4 pb-6">
        <span class="text-h2 font-weight-bold">{{ $t('reservationTableManage') }}</span>
      </div>
    </div>
    <div class="d-flex">
      <v-spacer/>
      <v-btn
        class="success mr-2"
        @click="openAllTable"
      >
        {{ $t('openAll') }}
      </v-btn>
      <v-btn
        class="warning"
        @click="closeAllTable"
      >
        {{ $t('closeAll') }}
      </v-btn>
    </div>
    <div
      class="mt-4"
      style="display: grid;grid-template-columns: repeat(8,minmax(0,1fr));grid-gap: 8px;width: 100%"
    >
      <v-card
        v-for="(t) in tableList"
        :key="t.id"
        :color="t.ifReserve?'primary':'#eeeeee'"
        :dark="t.ifReserve"
        class="pa-4"
        elevation="0"
        @click="changeReserveStatus(t)"
      >
        <v-responsive
          :aspect-ratio="1"
          style="position: relative"
        >
          <div
            class="d-flex align-center flex-column mt-6"
            style="height: 100%"
          >
            <div class="text-h4 font-weight-medium">
              {{ t.name }}
            </div>
            <div class="mt-2 d-flex align-center font-weight-regular text-caption">
              <p>{{ $t('tableForPeople', [t.seatCount ? t.seatCount : 0]) }}</p>
            </div>
          </div>
        </v-responsive>
      </v-card>
    </div>
    <loading-dialog :just-wait="justWait"/>
  </v-container>
</template>

<script>
  import Table from '@/model/tableAndPerson/Table'
  import { addTableReserve, getReserveSettings, getReserveTable, removeTableReserve } from '@/common/Utlis/api'
  import LoadingDialog from '@/composable/LoadingDialog'
  import { showSuccessMessage } from '@/common/utils'
  import { today } from '@/common/Repository/DateRepository'

  export default {
    name: 'UsefulReserveTable',
    components: { LoadingDialog, today },
    data: () => {
      return ({
        isLoading: null,
        sureToAdd: false,
        tableSeat: null,
        removeChair: null,
        isShowUsefulTable: false,
        justWait: false,
        tableList: [],
        needTable: null,
        showUsefulTable: null,
        reserveSetting: null,
        today,
        startTime: null,
        reservationDate: today,
        menu: false,
        openTime: null,
        datepicker: false,
        timeGap: null,
        adultCount: 1,
        childCount: 0,
      })
    },
    mounted () {
      this.reloadData()
    },
    methods: {
      async openAllTable () {
        this.justWait = true
        const res = this.tableList.filter(it => !it.ifReserve)
        for (const item of res) {
          await addTableReserve(item.id)
        }
        this.justWait = false
        await this.reloadData()
        showSuccessMessage(this.$t('setTableSuccess'))
      },
      async closeAllTable () {
        this.justWait = true
        const res = this.tableList.filter(it => it.ifReserve)
        for (const item of res) {
          await removeTableReserve(item.id)
        }
        this.justWait = false
        await this.reloadData()
        showSuccessMessage(this.$t('cancel_success'))
      },
      async changeReserveStatus (item) {
        if (!item.ifReserve) {
          this.justWait = true
          await addTableReserve(item.id)
          this.justWait = false
          await this.reloadData()
          showSuccessMessage(this.$t('setTableSuccess'))
        } else {
          this.justWait = true
          await removeTableReserve(item.id)
          this.justWait = false
          await this.reloadData()
          showSuccessMessage(this.$t('cancel_success'))
        }
        await this.reloadData()
      },
      async reloadData () {
        const res = new Set((await getReserveTable()).map(it => parseInt(it.id)))
        this.tableList = (await Table.getList(true)).map(x => {
          x.ifReserve = res.has(x.id)
          return x
        })
        this.reserveSetting = (await getReserveSettings())
      },
    },
  }
</script>

<style scoped>

</style>
